import {
  AfterViewInit,
  Component,
  Inject,
  NgZone,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import pack from '../../package.json';
import { NgIf, isPlatformServer } from '@angular/common';
import { CheckUpdateService } from './shared/services/update/check-update.service';
import { Loader } from '@googlemaps/js-api-loader';
import { RouterOutlet } from '@angular/router';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgIf, RouterOutlet],
})
export class AppComponent implements AfterViewInit {
  title = 'enrollhere-web';
  version = pack.version;

  private _logoutTimer?: NodeJS.Timeout;

  //do not remove checkUpdateService as it then stops to update automatically
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private ngZone: NgZone,
    private updateService: CheckUpdateService
  ) {}

  ngAfterViewInit() {
    this.loadMaps();
    // this.initAuthTimeout();
  }

  loadMaps() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const loader = new Loader({
      apiKey: environment.googleMapsApiKey,
      version: 'weekly',
    });

    loader.load().then(async () => {
      console.log('google maps loaded');
    });

    // console.log('preparing to load...');
    // const node = this.document.createElement('script');
    // node.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=places,visualization&callback=initMap`;
    // node.type = 'text/javascript';
    // node.async = true;
    // // node.charset = 'utf-8';
    // this.document.getElementsByTagName('head')[0].appendChild(node);
  }

  private initAuthTimeout() {
    this.ngZone.runOutsideAngular(() => {
      document.addEventListener('mousemove', () => this.resetLogoutTimer());
      document.addEventListener('keydown', () => this.resetLogoutTimer());
      document.addEventListener('click', () => this.resetLogoutTimer());
      document.addEventListener('scroll', () => this.resetLogoutTimer());

      this.resetLogoutTimer();
    });
  }

  private resetLogoutTimer() {
    const AUTO_LOGOUT_TIME = 60 * 60 * 1000;

    if (this._logoutTimer) {
      clearTimeout(this._logoutTimer);
    }

    this._logoutTimer = setTimeout(() => {
      const authService = inject(AuthService);
      authService.logout();
    }, AUTO_LOGOUT_TIME);
  }
}
