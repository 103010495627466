import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PublisherActions } from './publisher.actions';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Publisher, UserType } from 'src/app/shared/models';
import { Action } from '@ngrx/store';

@Injectable()
export class PublisherEffects {
  loadPublishers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PublisherActions.loadPublishers),
      switchMap(() => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'publishers'),
            snapshot => {
              const publishers = snapshot.docs.map(doc =>
                Publisher.fromJSON({
                  ...doc.data(),
                  id: doc.id,
                  userType: UserType.PUBLISHER,
                })
              );
              subscriber.next(
                PublisherActions.loadPublishersSuccess({ publishers })
              );
            },
            error => {
              subscriber.next(
                PublisherActions.loadPublishersFailure({ error })
              );
            }
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError(error =>
            of({ type: '[Publisher API] Load Publishers Error', error })
          )
        );
      })
    );
  });

  loadPublisher$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PublisherActions.loadPublisher),
      switchMap(({ publisherId }) => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'publishers', publisherId),
            snapshot => {
              const publisher = Publisher.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
                userType: UserType.PUBLISHER,
              });
              subscriber.next(
                PublisherActions.loadPublisherSuccess({ publisher })
              );
            },
            error => {
              subscriber.next(PublisherActions.loadPublisherFailure({ error }));
            }
          );
          return unsubscribe;
        });
      })
    );
  });

  addPublisher$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PublisherActions.addPublisher),
      mergeMap(async ({ publisher }) => {
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'publishers'),
            publisher
          );
          return PublisherActions.addPublisherSuccess({
            publisher: Publisher.fromJSON({ ...publisher, id: docRef.id }),
          }); // return new publisher with id
        } catch (error) {
          return PublisherActions.addPublisherFailure({
            error: error as Error,
          });
        }
      })
    );
  });

  removePublisher$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PublisherActions.removePublisher),
      mergeMap(async ({ publisherId }) => {
        try {
          await deleteDoc(doc(this.firestore, 'publishers', publisherId));
          return PublisherActions.removePublisherSuccess({ publisherId }); // return removed publisher's id
        } catch (error) {
          return PublisherActions.removePublisherFailure({
            error: error as Error,
          });
        }
      })
    );
  });

  updatePublisher$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PublisherActions.updatePublisher),
      mergeMap(async ({ publisherId, publisher }) => {
        try {
          await setDoc(
            doc(this.firestore, 'publishers', publisherId),
            publisher,
            {
              merge: true,
            }
          );
          return PublisherActions.updatePublisherSuccess({
            publisherId,
            publisher,
          }); // return updated publisher's id and changes
        } catch (error) {
          return PublisherActions.updatePublisherFailure({
            error: error as Error,
          });
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore
  ) {}
}
