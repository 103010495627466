/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AgentActions } from './agent.actions';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Agent, UserType } from 'src/app/shared/models';
import { Action } from '@ngrx/store';

@Injectable()
export class AgentEffects {
  loadAgents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgentActions.loadAgents),
      mergeMap(() => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'agents'),
            snapshot => {
              const agents = snapshot.docs.map(doc =>
                Agent.fromJSON({
                  ...doc.data(),
                  id: doc.id,
                  userType: UserType.AGENT,
                })
              );
              subscriber.next(AgentActions.loadAgentsSuccess({ agents }));
            },
            error => {
              subscriber.next(AgentActions.loadAgentsFailure({ error }));
            }
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError(error =>
            of({ type: '[Agent API] Load Agents Error', error })
          )
        );
      })
    );
  });

  loadAgent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgentActions.loadAgent),
      mergeMap(({ agentId }) => {
        return new Observable<Action>(subscriber => {
          console.log('loading agent', agentId);
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'agents', agentId),
            snapshot => {
              const agent = Agent.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
                userType: UserType.AGENT,
              });
              console.log('got agent', agent);
              subscriber.next(AgentActions.loadAgentSuccess({ agent }));
            },
            error => {
              subscriber.next(AgentActions.loadAgentFailure({ error }));
            }
          );
          return unsubscribe;
        });
      })
    );
  });

  addAgent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgentActions.addAgent),
      mergeMap(async ({ agent }) => {
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'agents'),
            Agent.toJSON(agent) as any
          );
          return AgentActions.addAgentSuccess({
            agent: { ...agent, id: docRef.id },
          }); // return new agent with id
        } catch (error) {
          return AgentActions.addAgentFailure({ error: error as Error });
        }
      })
    );
  });

  removeAgent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgentActions.removeAgent),
      mergeMap(async ({ agentId }) => {
        try {
          await deleteDoc(doc(this.firestore, 'agents', agentId));
          return AgentActions.removeAgentSuccess({ agentId }); // return removed agent's id
        } catch (error) {
          return AgentActions.removeAgentFailure({ error: error as Error });
        }
      })
    );
  });

  updateAgent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgentActions.updateAgent),
      mergeMap(async ({ agentId, agent }) => {
        try {
          await setDoc(
            doc(this.firestore, 'agents', agentId),
            Agent.toJSON(agent) as any,
            {
              merge: true,
            }
          );
          return AgentActions.updateAgentSuccess({
            agentId,
            agent,
          }); // return updated agent's id and changes
        } catch (error) {
          return AgentActions.updateAgentFailure({ error: error as Error });
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore
  ) {}
}
