import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthorityActions } from './authority.actions';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Authority, UserType } from 'src/app/shared/models';
import { Action } from '@ngrx/store';

@Injectable()
export class AuthorityEffects {
  loadAuthorities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthorityActions.loadAuthorities),
      switchMap(() => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'authorities'),
            snapshot => {
              const authorities = snapshot.docs.map(doc =>
                Authority.fromJSON({
                  ...doc.data(),
                  id: doc.id,
                  userType: UserType.AUTHORITY,
                })
              );
              subscriber.next(
                AuthorityActions.loadAuthoritiesSuccess({ authorities })
              );
            },
            error => {
              subscriber.next(
                AuthorityActions.loadAuthoritiesFailure({ error })
              );
            }
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError(error =>
            of({ type: '[Authority API] Load Authorities Error', error })
          )
        );
      })
    );
  });

  loadAuthority$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthorityActions.loadAuthority),
      switchMap(({ authorityId }) => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'authorities', authorityId),
            snapshot => {
              const authority = Authority.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
                userType: UserType.AUTHORITY,
              });
              subscriber.next(
                AuthorityActions.loadAuthoritySuccess({ authority })
              );
            },
            error => {
              subscriber.next(AuthorityActions.loadAuthorityFailure({ error }));
            }
          );
          return unsubscribe;
        });
      })
    );
  });

  addAuthority$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthorityActions.addAuthority),
      mergeMap(async ({ authority }) => {
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'authorities'),
            authority
          );
          return AuthorityActions.addAuthoritySuccess({
            authority: Authority.fromJSON({ ...authority, id: docRef.id }),
          }); // return new authority with id
        } catch (error) {
          return AuthorityActions.addAuthorityFailure({ error });
        }
      })
    );
  });

  removeAuthority$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthorityActions.removeAuthority),
      mergeMap(async ({ authorityId }) => {
        try {
          await deleteDoc(doc(this.firestore, 'authorities', authorityId));
          return AuthorityActions.removeAuthoritySuccess({ authorityId }); // return removed authority's id
        } catch (error) {
          return AuthorityActions.removeAuthorityFailure({ error });
        }
      })
    );
  });

  updateAuthority$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthorityActions.updateAuthority),
      mergeMap(async ({ authorityId, authority }) => {
        try {
          await setDoc(
            doc(this.firestore, 'authorities', authorityId),
            authority,
            {
              merge: true,
            }
          );
          return AuthorityActions.updateAuthoritySuccess({
            authorityId,
            authority,
          }); // return updated authority's id and changes
        } catch (error) {
          return AuthorityActions.updateAuthorityFailure({ error });
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore
  ) {}
}
